import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit'
import { apiGetDAItems,apiGetDAs, apiGetItemBillID, apiPostDA, apiDeleteDA, apiPutDA, apiGetDAByID, apiPostDAItem, apiDeleteDAItem, apiPutDAItem, apiGetItemQty } from 'services/APIDA'
import _ from 'lodash';

export const getDAByID = createAsyncThunk('DAs/data/getDAByID', async (id, { rejectWithValue }) => {

    try {
        const response = await apiGetDAByID(id)

        return response.data
    } catch (err) {
        //console.log(err)
        // Use `err.response.data` as `action.payload` for a `rejected` action,
        // by explicitly returning it using the `rejectWithValue()` utility
        return rejectWithValue(err.response.data)
    }
})


 

export const getItemQty = createAsyncThunk('DAs/data/getItemQty', async (id, { rejectWithValue }) => {

    try {
        const response = await apiGetItemQty(id)

        return response.data
    } catch (err) {
        //console.log(err)
        // Use `err.response.data` as `action.payload` for a `rejected` action,
        // by explicitly returning it using the `rejectWithValue()` utility
        return rejectWithValue(err.response.data)
    }
})
 

export const getDBDAs = createAsyncThunk('DAs/data/getDBDAs', async ( data,{ rejectWithValue }) => {

    try {
        const response = await apiGetDAs()

        return response.data
    } catch (err) {
        //console.log(err)
        // Use `err.response.data` as `action.payload` for a `rejected` action,
        // by explicitly returning it using the `rejectWithValue()` utility
        return rejectWithValue(err.response.data)
    }
})

export const getDBDAItems = createAsyncThunk('DAs/data/getDBDAItems', async (data, { rejectWithValue }) => {

    try {
        const response = await apiGetDAItems()

        return response.data
    } catch (err) {
        //console.log(err)
        // Use `err.response.data` as `action.payload` for a `rejected` action,
        // by explicitly returning it using the `rejectWithValue()` utility
        return rejectWithValue(err.response.data)
    }
})
 

export const getDAs = createAsyncThunk('DAs/data/GetDAs', async (data) => {

    return data
})
export const PostDA = createAsyncThunk('DAs/data/PostDA', async (data, { rejectWithValue }) => {

    try {
        const response = await apiPostDA(data)

        return response.data
    } catch (err) {
        //console.log(err)
        // Use `err.response.data` as `action.payload` for a `rejected` action,
        // by explicitly returning it using the `rejectWithValue()` utility
        return rejectWithValue(err.response.data)
    }
})
 


export const DeleteDA = createAsyncThunk('DAs/data/DeleteDA', async (data, { rejectWithValue }) => {

    try {
        const response = await apiDeleteDA(data)

        return response.data
    } catch (err) {
        //console.log(err)
        // Use `err.response.data` as `action.payload` for a `rejected` action,
        // by explicitly returning it using the `rejectWithValue()` utility
        return rejectWithValue(err.response.data)
    }
})
    

export const putDA = createAsyncThunk('DAs/data/putDA', async (data, { rejectWithValue }) => {

    try {
        const response = await apiPutDA(data)

        return response.data
    } catch (err) {
        //console.log(err)
        // Use `err.response.data` as `action.payload` for a `rejected` action,
        // by explicitly returning it using the `rejectWithValue()` utility
        return rejectWithValue(err.response.data)
    }
})

 //
export const getItemBillId = createAsyncThunk('DAs/data/getItemBillId', async (id, { rejectWithValue }) => {

    try {
        const response = await apiGetItemBillID(id)

        return response.data
    } catch (err) {
        //console.log(err)
        // Use `err.response.data` as `action.payload` for a `rejected` action,
        // by explicitly returning it using the `rejectWithValue()` utility
        return rejectWithValue(err.response.data)
    }
})



export const PostDAItem = createAsyncThunk('DAs/data/PostDAItem', async (data, { rejectWithValue }) => {

    try {
        const response = await apiPostDAItem(data)

        return response.data
    } catch (err) {
        //console.log(err)
        // Use `err.response.data` as `action.payload` for a `rejected` action,
        // by explicitly returning it using the `rejectWithValue()` utility
        return rejectWithValue(err.response.data)
    }
})

 


export const DeleteDAItem = createAsyncThunk('DAs/data/DeleteDAItem', async (data, { rejectWithValue }) => {

    try {
        const response = await apiDeleteDAItem(data)

        return response.data
    } catch (err) {
        //console.log(err)
        // Use `err.response.data` as `action.payload` for a `rejected` action,
        // by explicitly returning it using the `rejectWithValue()` utility
        return rejectWithValue(err.response.data)
    }
})
 

export const putDAItem = createAsyncThunk('DAs/data/putDAItem', async (data, { rejectWithValue }) => {

    try {
        const response = await apiPutDAItem(data)

        return response.data
    } catch (err) {
        //console.log(err)
        // Use `err.response.data` as `action.payload` for a `rejected` action,
        // by explicitly returning it using the `rejectWithValue()` utility
        return rejectWithValue(err.response.data)
    }
})

 


export const initialTableData = {
    total: 0,
    pageIndex: 1,
    pageSize: 10,
    query: '',
    sort: {
        order: '',
        key: ''
    }
}

export const initialFilterData = {
    status: '',
}

const dataSlice = createSlice({
    name: 'DAs/data',
    initialState: {
        loading: false,
        loadingDB: false,
        error: "",
        DAList: [],
        DADBList: [],
        DAItemDBList:[],
        statisticData: {},
        selectedDA: {},
        DaNewID: 0,
        selectedDAItem: {},
        excelDAData:[],
        excelDAItemsData: [],
        selectedItemQty: [],
        itembillid: {},
        isEdit: false,
        isNew: false,
       // alldata:{},
        tableData: initialTableData,
        filterData: initialFilterData,
        filterDate: { start: new Date(), end: new Date() },
    },
    reducers: {
        setTableData: (state, action) => {
            state.tableData = action.payload
        },
        setBackToTable: (state, action) => {
            state.isEdit = false
            state.isNew = false
        },
        setDAList: (state, action) => {
            state.DAList = action.payload
        },
        setFilterData: (state, action) => {
            state.filterData = action.payload
        },
        setFilterDate: (state, action) => {
            state.filterDate = action.payload
        }, setSelectedDA: (state, action) => {
            state.selectedDA = action.payload
            state.isEdit = true
            state.isNew = false

        }, setSelectedDADelete: (state, action) => {
            state.selectedDA = action.payload

        }, setSelectedItemQty: (state, action) => {
            state.selectedItemQty = action.payload

        },

        setExcelDAData: (state, action) => {
            state.excelDAData = _.orderBy(action.payload, ['id'], ['desc']); 
        },
        setExcelDAItemsData: (state, action) => {
            state.excelDAItemsData = _.orderBy(action.payload, ['id'], ['desc']); 
        },
        setNewDA: (state, action) => {
            state.selectedDA = {}
            state.isEdit = false
            state.isNew = true
        },

    },
    extraReducers: (builder) => {
        builder

        
            .addCase(getItemBillId.fulfilled, (state, action) => {

                state.itembillid = action.payload
                state.error = ""

            })
            .addCase(getItemQty.fulfilled, (state, action) => {

                state.selectedItemQty = action.payload

                state.loading = false
                state.error = ""
            })
            .addCase(getItemQty.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(getItemQty.pending, (state, action) => {
                state.loading = true

            })
            .addCase(getDAs.fulfilled, (state, action) => {
                state.DAList = action.payload.data
                state.tableData.total = action.payload.total
                state.loading = false
                state.error = ""
            })
            .addCase(getDAs.rejected, (state, action) => {
                state.loading = false
                //state.error = action.payload
            })
            .addCase(getDAs.pending, (state, action) => {
                state.loading = true
            })

            //getDBDAItems
            .addCase(getDBDAItems.fulfilled, (state, action) => {
                state.DAItemDBList = action.payload
                state.loadingDB = false
                state.error = ""

            })
            .addCase(getDBDAItems.rejected, (state, action) => {
             
                if (action.payload) state.error = action.payload
                state.loadingDB = false

            })
            .addCase(getDBDAItems.pending, (state, action) => {
                state.loadingDB = true
            })



            .addCase(getDBDAs.fulfilled, (state, action) => {
                state.DADBList = action.payload
                state.loadingDB = false
                state.error = ""
            })
            .addCase(getDBDAs.rejected, (state, action) => {
                //console.log(action.payload)
                if (action.payload) state.error = action.payload
                state.loadingDB = false
              
            })
            .addCase(getDBDAs.pending, (state, action) => {
                state.loadingDB = true
            })
            .addCase(PostDA.pending, (state, action) => {
                state.loadingDB = true
            })
            .addCase(PostDA.fulfilled, (state, action) => {
                state.selectedDA = action.payload
                state.DADBList = [...state.DADBList, action.payload]
                state.loadingDB = false
                state.error = ""
            })

            .addCase(DeleteDA.fulfilled, (state, action) => {
                const sda = current(state.selectedDA)
                const DAList_ = current(state.DAList)
                const DADBList_ = current(state.DADBList)
                return {
                    ...state,
                    DAList: DAList_.filter((i) => i.id !== sda.id),
                    DADBList: DADBList_.filter((i) => i.id !== sda.id),
                    loading: false,
                    error : ""
                }
            })
            .addCase(DeleteDA.rejected, (state, action) => {
                //console.log(action.payload)
                state.error = action.payload
                state.loading = false
            })
            .addCase(DeleteDA.pending, (state, action) => {
               // state.loading = true
            })
            .addCase(getDAByID.fulfilled, (state, action) => {

                state.selectedDA = action.payload
                state.isEdit = true
                state.isNew = false
                const dalist_ = [...state.DADBList]
                const newdalist = dalist_.map((da_) => da_.id === action.payload.id ? action.payload : da_)
                state.DADBList = _.orderBy(newdalist, ['id'], ['desc']);  
                state.loading = false
               
            })
            .addCase(getDAByID.rejected, (state, action) => {
                state.error = action.payload
                state.loading = false
         
            })
            .addCase(getDAByID.pending, (state, action) => {
             state.loading = true
            })
            ////////////////////items///////////////
            .addCase(PostDAItem.fulfilled, (state, action) => {
                state.selectedDAItem = action.payload
                state.loading = false
                state.error = ""
            })
            .addCase(PostDAItem.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(PostDAItem.pending, (state, action) => {
                //state.loading = true
            })
            .addCase(putDAItem.fulfilled, (state, action) => {
                state.selectedDAItem = action.payload

                state.loading = false
                state.error = ""
            })
            .addCase(putDAItem.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(putDAItem.pending, (state, action) => {
               // state.loading = true
            })
            .addCase(DeleteDAItem.fulfilled, (state, action) => {
                // //console.log(action.payload)
                // state.DAList = state.DAList.filter((i) => i.id !== action.payload.id)
                state.loading = false
                state.error = ""
            })
            .addCase(DeleteDAItem.rejected, (state, action) => {
                
                state.error = action.payload 
                state.loading = false
            })
            .addCase(DeleteDAItem.pending, (state, action) => {
               // state.loading = true
            })
    },
})

export const {
    setTableData,
    setDAList,
    setFilterData,
    setFilterDate,
    setExcelDAData,
    setExcelDAItemsData,
    setSelectedDA,
    setSelectedDADelete,
    setNewDA,
    setBackToTable,
    setSelectedItemQty
} = dataSlice.actions

export default dataSlice.reducer
