import ApiService from "./ApiService"



export async function apiGetItem() {
    return ApiService.fetchData({
        url: '/api/Item/?ordering=-id',
        method: 'get'
    })
}

export async function apiPostItem(data) {
    return ApiService.fetchData({
        url: '/api/Item/',
        method: 'post',
        data
    })
}

export async function apiPutItem(data) {
    return ApiService.fetchData({
        url: '/api/Item/' + data.id + '/',
        method: 'put',
        data
    })
}

export async function apiDeleteItem(data) {
    console.log(data)
    return ApiService.fetchData({
        url: '/api/Item/' + data.id + '/',
        method: 'delete',
        data
    })
}

