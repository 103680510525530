import { combineReducers } from 'redux'
import theme from './theme/themeSlice'
import auth from './auth'
import base from './base'
import Admin from './admin'
import BOEs from '../views/action/BOE/store'
import DAs from '../views/action/DA/store'
const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        auth,
        base,
        BOEs,
        DAs,
        Admin,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}
  
export default rootReducer
