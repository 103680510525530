import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit'
import { apiGetCustomsBOEs, apiGetBOEs, apiPostBOE, apiDeleteBOE, apiPutBOE, apiGetBOEByID, apiPostBOEItem, apiDeleteBOEItem, apiPutBOEItem, apiGetBOEsItems } from 'services/APIBOE'
import _ from 'lodash';
//

export const getCustomDBBOEs = createAsyncThunk('BOEs/data/getCustomDBBOEs', async (data, { rejectWithValue }) => {

    try {
        const response = await apiGetCustomsBOEs()

        return response.data
    } catch (err) {
        //console.log(err)
        // Use `err.response.data` as `action.payload` for a `rejected` action,
        // by explicitly returning it using the `rejectWithValue()` utility
        return rejectWithValue(err.response.data)
    }
})


export const getBOEByID = createAsyncThunk('BOEs/data/getBOEByID', async (id, { rejectWithValue }) => {

    try {
        const response = await apiGetBOEByID(id)

        return response.data
    } catch (err) {
        //console.log(err)
        // Use `err.response.data` as `action.payload` for a `rejected` action,
        // by explicitly returning it using the `rejectWithValue()` utility
        return rejectWithValue(err.response.data)
    }
})



export const getDBBOEsItems = createAsyncThunk('BOEs/data/apiGetBOEsItems', async (data,{ rejectWithValue }) => {

    try {
        const response = await apiGetBOEsItems()

        return response.data
    } catch (err) {
        //console.log(err)
        // Use `err.response.data` as `action.payload` for a `rejected` action,
        // by explicitly returning it using the `rejectWithValue()` utility
        return rejectWithValue(err.response.data)
    }
})



export const getDBBOEs = createAsyncThunk('BOEs/data/getBOEsForTb', async (data,{ rejectWithValue }) => {

    try {
        const response = await apiGetBOEs()

        return response.data
    } catch (err) {
        //console.log(err)
        // Use `err.response.data` as `action.payload` for a `rejected` action,
        // by explicitly returning it using the `rejectWithValue()` utility
        return rejectWithValue(err.response.data)
    }
})

 
export const getBOEs = createAsyncThunk('BOEs/data/GetBOEs', async (data) => {

    return data
})

export const getBOEsCustom = createAsyncThunk('BOEs/data/getBOEsCustom', async (data) => {

    return data
})
export const PostBOE = createAsyncThunk('BOEs/data/PostBOE', async (data, { rejectWithValue }) => {
    try {
        const response = await apiPostBOE(data)
        return response.data
        
    } catch (err) {
        //console.log(err)
        // Use `err.response.data` as `action.payload` for a `rejected` action,
        // by explicitly returning it using the `rejectWithValue()` utility
        return rejectWithValue(err.response.data)
    }
})


export const DeleteBOE = createAsyncThunk('BOEs/data/DeleteBOE', async (data, { rejectWithValue }) => {
    try {
        const response = await apiDeleteBOE(data)
        return response.data
    } catch (err) {
        ////console.log(err)
        // Use `err.response.data` as `action.payload` for a `rejected` action,
        // by explicitly returning it using the `rejectWithValue()` utility
        return rejectWithValue(err.response.data)
    }
})




export const putBOE = createAsyncThunk('BOEs/data/putBOE', async (data, { rejectWithValue }) => {

    try {
        const response = await apiPutBOE(data)

        return response.data
    } catch (err) {
        //console.log(err)
        // Use `err.response.data` as `action.payload` for a `rejected` action,
        // by explicitly returning it using the `rejectWithValue()` utility
        return rejectWithValue(err.response.data)
    }
})



export const PostBOEItem = createAsyncThunk('BOEs/data/PostBOEItem', async (data, { rejectWithValue }) => {

    try {
        const response = await apiPostBOEItem(data)

        return response.data
    } catch (err) {
        //console.log(err)
        // Use `err.response.data` as `action.payload` for a `rejected` action,
        // by explicitly returning it using the `rejectWithValue()` utility
        return rejectWithValue(err.response.data)
    }
})





export const DeleteBOEItem = createAsyncThunk('BOEs/data/DeleteBOEItem', async (data, { rejectWithValue }) => {

    try {
        const response = await apiDeleteBOEItem(data)

        return response.data
    } catch (err) {
        //console.log(err)
        // Use `err.response.data` as `action.payload` for a `rejected` action,
        // by explicitly returning it using the `rejectWithValue()` utility
        return rejectWithValue(err.response.data)
    }
})



export const putBOEItem = createAsyncThunk('BOEs/data/putBOEItem', async (data, { rejectWithValue }) => {
    try {
        const response = await apiPutBOEItem(data)
        return response.data
    } catch (err) {
        //console.log(err)
        // Use `err.response.data` as `action.payload` for a `rejected` action,
        // by explicitly returning it using the `rejectWithValue()` utility
        return rejectWithValue(err.response.data)
    }
})



export const initialTableData = {
    total: 0,
    pageIndex: 1,
    pageSize: 10,
    query: '',
    sort: {
        order: '',
        key: ''
    }
}
export const initialTableDataCustom = {
    total: 0,
    pageIndex: 1,
    pageSize: 10,
    query: '',
    sort: {
        order: '',
        key: ''
    }
}

export const initialFilterData = {
    status: '',
}
export const initialFilterDataCustom = {
    status: '',
}

const dataSlice = createSlice({
    name: 'BOEs/data',
    initialState: {
        loading: false,
        loadingDB: false,
        boeList: [],
        boeListCustom: [],
        boeDBList: [],
        excelBOEData:[],
        excelBOEItemsData: [],
        itemBill: [],
        statisticData: {},
        selectedBOE: {},
        selectedBOEItem: {},
        customsdb:[],
        isEdit: false,
        error: "",
        isNew: false,
        tableData: initialTableData,
        filterData: initialFilterData,
        filterDate: { start: new Date(), end: new Date() },
        tableDataCustom: initialTableDataCustom,
        filterDataCustom: initialFilterDataCustom,
        filterDateCustom: { start: new Date(), end: new Date() },
    },
    reducers: {
        setTableData: (state, action) => {
            state.tableData = action.payload
        },
        setTableDataCustom: (state, action) => {
            state.tableDataCustom = action.payload
        },
        setExcelBOEData: (state, action) => {
            state.excelBOEData = _.orderBy(action.payload, ['id'], ['desc']); 
        },
        setExcelBOEItemsData: (state, action) => {
            state.excelBOEItemsData = _.orderBy(action.payload, ['id'], ['desc']); 
        },
        setBackToTable: (state, action) => {
            state.isEdit = false
            state.isNew = false
        },
        setBOEList: (state, action) => {
            state.boeList = action.payload
        },
        setFilterData: (state, action) => {
            state.filterData = action.payload
        },
        setFilterDate: (state, action) => {
            state.filterDate = action.payload
        }, 
        setFilterDataCustom: (state, action) => {
            state.filterDataCustom = action.payload
        },
        setFilterDateCustom: (state, action) => {
            state.filterDateCustom = action.payload
        }, 
        setSelectedBOE: (state, action) => {
            state.selectedBOE = action.payload
            state.isEdit = true
            state.isNew = false
        },
        setSelectedDeleteBOE: (state, action) => {
            state.selectedBOE = action.payload
        
        },
        setNewBOE: (state, action) => {
            state.selectedBOE = {}
            state.isEdit = false
            state.isNew = true
        },

    },
    extraReducers: (builder) => {
        builder
            .addCase(DeleteBOE.fulfilled, (state, action) => {
                const sda = current(state.selectedBOE)
                const BOEList_ = current(state.boeList)
                const BOEDBList_ = current(state.boeDBList)
                return {
                    ...state,
                    boeList: BOEList_.filter((i) => i.id !== sda.id),
                    boeDBList: BOEDBList_.filter((i) => i.id !== sda.id),
                    loading: false,
                    error: ""
                }
                 
            })
            .addCase(DeleteBOE.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(DeleteBOE.pending, (state, action) => {
                //  state.loading = true

            })
            //
            .addCase(getCustomDBBOEs.rejected, (state, action) => {
                state.loadingDB = false
                state.error = action.payload
            })
            .addCase(getCustomDBBOEs.pending, (state, action) => {
                state.loadingDB = true

            })
            .addCase(getCustomDBBOEs.fulfilled, (state, action) => {
                state.customsdb = _.orderBy(action.payload, ['BEDate'], ['desc']);  
                state.loadingDB = false
                state.error = ""
            })
            //getBOEsCustom
            .addCase(getBOEsCustom.fulfilled, (state, action) => {
                state.boeListCustom = action.payload.data
                state.tableDataCustom.total = action.payload.total
                state.loading = false
                state.error = ""
            })
            .addCase(getBOEsCustom.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(getBOEsCustom.pending, (state, action) => {
                state.loading = true
            })



            .addCase(getBOEs.fulfilled, (state, action) => {
                state.boeList = action.payload.data
                state.tableData.total = action.payload.total
                state.loading = false
                state.error = ""
            })
            .addCase(getBOEs.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(getBOEs.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getDBBOEs.fulfilled, (state, action) => {
                state.boeDBList = action.payload
                state.loadingDB = false
                state.error = ""
            })
            .addCase(getDBBOEs.rejected, (state, action) => {
                state.loadingDB = false
                state.error = action.payload
            })
            .addCase(getDBBOEs.pending, (state, action) => {
               state.loadingDB = true
            })
            .addCase(PostBOE.pending, (state, action) => {
               // state.loadingDB = true
            })
            .addCase(PostBOE.fulfilled, (state, action) => {
                state.selectedBOE = action.payload
                state.boeDBList = [...state.boeDBList, action.payload]
                state.loadingDB = false
                state.error = ""
            })
          

            .addCase(getBOEByID.fulfilled, (state, action) => {
                state.selectedBOE = action.payload
                state.isEdit = true
                state.isNew = false
                const BOElist_ = [...state.boeDBList]
                const newBOElist = BOElist_.map((boe_) => boe_.id === action.payload.id ? action.payload : boe_)
                state.boeDBList = _.orderBy(newBOElist, ['id'], ['desc']);
                state.loading = false
              
            
            })
            .addCase(getBOEByID.rejected, (state, action) => {
                state.loading = false
                
            })
            .addCase(getBOEByID.pending, (state, action) => {
                state.loading = true
            })

            ////////////////////items///////////////
            .addCase(getDBBOEsItems.fulfilled, (state, action) => {
                state.itemBill = action.payload
                state.loading = false
                state.error = ""
            })
            .addCase(getDBBOEsItems.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(getDBBOEsItems.pending, (state, action) => {
             //   state.loading = true
            })
            .addCase(PostBOEItem.fulfilled, (state, action) => {
                state.selectedBOEItem = action.payload
                state.loading = false
                state.error = ""
            })
            .addCase(PostBOEItem.rejected, (state, action) => {
                state.loading = false
                ////console.log(action.payload)
                state.error = action.payload
            })
            .addCase(PostBOEItem.pending, (state, action) => {
               // state.loading = true
            })
            .addCase(putBOEItem.fulfilled, (state, action) => {
                state.selectedBOEItem = action.payload
                state.loading = false
                state.error = ""
            })
            .addCase(putBOEItem.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(putBOEItem.pending, (state, action) => {
             //   state.loading = true
            })
            .addCase(DeleteBOEItem.fulfilled, (state, action) => {

             //   state.boeList = action.payload.data
                state.loading = false
                state.error = ""
            })
            .addCase(DeleteBOEItem.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(DeleteBOEItem.pending, (state, action) => {
              //  state.loading = true
              
            })
    },
})

export const {
    setTableData,
    setTableDataCustom,
    setBOEList,
    setFilterData,
    setFilterDate,
    setFilterDataCustom,
    setFilterDateCustom,
    setSelectedBOE,
    setExcelBOEData,
    setExcelBOEItemsData,
    setNewBOE,
    setBackToTable,
    setSelectedDeleteBOE,
} = dataSlice.actions

export default dataSlice.reducer
