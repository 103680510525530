import ApiService from "./ApiService"



export async function apiGetInvoicecurerncy() {
    return ApiService.fetchData({
        url: '/api/Invoicecurerncy/?ordering=-id',
        method: 'get'
    })
}

export async function apiPostInvoicecurerncy(data) {
    return ApiService.fetchData({
        url: '/api/Invoicecurerncy/',
        method: 'post',
        data
    })
}

export async function apiPutInvoicecurerncy(data) {
    return ApiService.fetchData({
        url: '/api/Invoicecurerncy/' + data.id + '/',
        method: 'put',
        data
    })
}

export async function apiDeleteInvoicecurerncy(data) {
    return ApiService.fetchData({
        url: '/api/Invoicecurerncy/' + data.id + '/',
        method: 'delete',
        data
    })
}

