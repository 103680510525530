import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiGetAdvicekind, apiPostAdvicekind, apiDeleteAdvicekind, apiPutAdvicekind,apiGetStockinguom, apiPostStockinguom, apiDeleteStockinguom, apiPutStockinguom } from 'services/StockingUOM';
import { apiGetInvoicecurerncy, apiPostInvoicecurerncy, apiDeleteInvoicecurerncy, apiPutInvoicecurerncy } from 'services/APIInvoiceCurrency';
import { apiGetCountryoforigin, apiPostCountryoforigin, apiDeleteCountryoforigin, apiPutCountryoforigin } from 'services/APICountryoforigin'
import { apiGetCurrencyexchange, apiPostCurrencyexchange, apiDeleteCurrencyexchange, apiPutCurrencyexchange } from 'services/APICurrencyExchange'
import { apiGetItem, apiPostItem, apiDeleteItem, apiPutItem } from 'services/APIItems'

///////Stockinguom
 
export const getAdvicekind = createAsyncThunk('Advicekind/data/GetAdvicekind', async () => {
    const response = await apiGetAdvicekind()
    return response.data
})



export const postAdvicekind = createAsyncThunk('Advicekind/data/postAdvicekind', async (data) => {
    const response = await apiPostAdvicekind(data)
    return response.data
})
export const DeleteAdvicekind = createAsyncThunk('Advicekind/data/DeleteAdvicekind', async (params) => {
    const response = await apiDeleteAdvicekind(params)
    return response.data
})


export const putAdvicekind = createAsyncThunk('Advicekind/data/putAdvicekind', async (data) => {
    const response = await apiPutAdvicekind(data)
    return response.data
})




export const getStockinguom = createAsyncThunk('Stockinguom/data/getStockinguom', async () => {
    const response = await apiGetStockinguom()
    return response.data
})
export const postStockinguom = createAsyncThunk('Stockinguom/data/postStockinguom', async (data) => {
    const response = await apiPostStockinguom(data)
    return response.data
})
export const DeleteStockinguom = createAsyncThunk('Stockinguom/data/DeleteStockinguom', async (params) => {
    const response = await apiDeleteStockinguom(params)
    return response.data
})


export const putStockinguom = createAsyncThunk('Stockinguom/data/putStockinguom', async (data) => {
    const response = await apiPutStockinguom(data)
    return response.data
})

///////Item

export const getItem = createAsyncThunk('Item/data/getItem', async () => {
    const response = await apiGetItem()
    return response.data
})

export const postItem = createAsyncThunk('Item/data/postItem', async (data) => {
    const response = await apiPostItem(data)
    return response.data
})
export const DeleteItem = createAsyncThunk('Item/data/DeleteItem', async (params) => {
    const response = await apiDeleteItem(params)
    return response.data
})


export const putItem = createAsyncThunk('Item/data/putItem', async (data) => {
    const response = await apiPutItem(data)
    return response.data
})



///////Currencyexchange

export const getCurrencyexchange = createAsyncThunk('Currencyexchange/data/getCurrencyexchange', async () => {
    const response = await apiGetCurrencyexchange()
    return response.data
})

export const postCurrencyexchange = createAsyncThunk('Currencyexchange/data/postCurrencyexchange', async (data) => {
    const response = await apiPostCurrencyexchange(data)
    return response.data
})
export const DeleteCurrencyexchange = createAsyncThunk('Currencyexchange/data/DeleteCurrencyexchange', async (params) => {
    const response = await apiDeleteCurrencyexchange(params)
    return response.data
})


export const putCurrencyexchange = createAsyncThunk('Currencyexchange/data/putCurrencyexchange', async (data) => {
    const response = await apiPutCurrencyexchange(data)
    return response.data
})
///////Invoicecurerncy


export const getInvoicecurerncy = createAsyncThunk('Invoicecurerncy/data/getInvoicecurerncy', async () => {
    const response = await apiGetInvoicecurerncy()
    return response.data
})

export const postInvoicecurerncy = createAsyncThunk('Invoicecurerncy/data/postInvoicecurerncy', async (data) => {
    const response = await apiPostInvoicecurerncy(data)
    return response.data
})
export const DeleteInvoicecurerncy = createAsyncThunk('Invoicecurerncy/data/DeleteInvoicecurerncy', async (params) => {
    const response = await apiDeleteInvoicecurerncy(params)
    return response.data
})


export const putInvoicecurerncy = createAsyncThunk('Invoicecurerncy/data/putInvoicecurerncy', async (data) => {
    const response = await apiPutInvoicecurerncy(data)
    return response.data
})



///////Countryoforigin


export const getCountryoforigin = createAsyncThunk('Countryoforigin/data/getCountryoforigin', async () => {
    const response = await apiGetCountryoforigin()
    return response.data
})

export const postCountryoforigin = createAsyncThunk('Countryoforigin/data/postCountryoforigin', async (data) => {
    const response = await apiPostCountryoforigin(data)
    return response.data
})
export const DeleteCountryoforigin = createAsyncThunk('Countryoforigin/data/DeleteCountryoforigin', async (params) => {
    const response = await apiDeleteCountryoforigin(params)
    return response.data
})


export const putCountryoforigin = createAsyncThunk('Countryoforigin/data/putCountryoforigin', async (data) => {
 
    const response = await apiPutCountryoforigin(data)
    return response.data
})
  
const dataSlice = createSlice({
    name: 'Admin/data',
    initialState: {
        loading: false,
        loadingDB: false,
        Stockinguom: [],
        Invoicecurerncy:[],
        Countryoforigin:[],
        Currencyexchange: [],
        Advicekind:[],
        Item:[],
 
    },
    reducers: {
        setStockinguom: (state, action) => {
            state.Stockinguom = action.payload
        },
        setInvoicecurerncy: (state, action) => {
            state.Invoicecurerncy = action.payload
        },
         
        setCountryoforigin: (state, action) => {
            state.Countryoforigin = action.payload
        },
        setCurrencyexchange: (state, action) => {
            state.Currencyexchange = action.payload
        },
        setItem: (state, action) => {
            state.Item = action.payload
        },
        setAdvicekind: (state, action) => {
            state.Advicekind = action.payload
        },

    },
    extraReducers: {
        [getStockinguom.fulfilled]: (state, action) => {
            state.Stockinguom = action.payload
            state.loading = false
        },
        [getStockinguom.rejected]: (state) => {
            state.loading = false
        },
        [getStockinguom.pending]: (state) => {
            state.loading = true
        },

        [putStockinguom.fulfilled]: (state, action) => {

            const cor = [...state.Stockinguom]
            state.Stockinguom = cor.map((cor_) => cor_.id === action.payload.id ? action.payload : cor_)
            state.loading = false
        },
        [putStockinguom.rejected]: (state) => {
            state.loading = false
        },
        [putStockinguom.pending]: (state) => {
            state.loading = true
        },
        [postStockinguom.fulfilled]: (state, action) => {
            state.Stockinguom = [...state.Stockinguom, action.payload]
            state.loading = false
        },
        [postStockinguom.rejected]: (state) => {
            state.loading = false
        },
        [postStockinguom.pending]: (state) => {
            state.loading = true
        },

        [DeleteStockinguom.fulfilled]: (state, action) => {
            state.Invoicecurerncy = action.payload
            state.loading = false
        },
        [DeleteStockinguom.rejected]: (state) => {
            state.loading = false
        },
        [DeleteStockinguom.pending]: (state) => {
            state.loading = true
        },
        ////////////////////////////////////////

        [getAdvicekind.fulfilled]: (state, action) => {
            state.Advicekind = action.payload
            state.loading = false
        },
        [getAdvicekind.rejected]: (state) => {
            state.loading = false
        },
        [getAdvicekind.pending]: (state) => {
            state.loading = true
        },

        [putAdvicekind.fulfilled]: (state, action) => {

            const cor = [...state.Advicekind]
            state.Advicekind = cor.map((cor_) => cor_.id === action.payload.id ? action.payload : cor_)
            state.loading = false
        },
        [putAdvicekind.rejected]: (state) => {
            state.loading = false
        },
        [putAdvicekind.pending]: (state) => {
            state.loading = true
        },
        [postAdvicekind.fulfilled]: (state, action) => {
            state.Advicekind = [...state.Advicekind, action.payload]
            state.loading = false
        },
        [postAdvicekind.rejected]: (state) => {
            state.loading = false
        },
        [postAdvicekind.pending]: (state) => {
            state.loading = true
        },

        [DeleteAdvicekind.fulfilled]: (state, action) => {
            state.Advicekind = action.payload
            state.loading = false
        },
        [DeleteAdvicekind.rejected]: (state) => {
            state.loading = false
        },
        [DeleteAdvicekind.pending]: (state) => {
            state.loading = true
        },



        /////////////////////////////////////////////////
        [getItem.fulfilled]: (state, action) => {
            state.Item = action.payload
            state.loading = false
        },
        [getItem.rejected]: (state) => {
            state.loading = false
        },
        [getItem.pending]: (state) => {
            state.loading = true
        },
        

        [putItem.fulfilled]: (state, action) => {

            const cor = [...state.Item]
            state.Item = cor.map((cor_) => cor_.id === action.payload.id ? action.payload : cor_)
            state.loading = false
        },
        [putItem.rejected]: (state) => {
            state.loading = false
        },
        [putItem.pending]: (state) => {
            state.loading = true
        },
        [postItem.fulfilled]: (state, action) => {
            state.Item = [...state.Item, action.payload]
            state.loading = false
        },
        [postItem.rejected]: (state) => {
            state.loading = false
        },
        [postItem.pending]: (state) => {
            state.loading = true
        },

        [DeleteItem.fulfilled]: (state, action) => {
            state.Item = action.payload
            state.loading = false
        },
        [DeleteItem.rejected]: (state) => {
            state.loading = false
        },
        [DeleteItem.pending]: (state) => {
            state.loading = true
        },

        ////////////////////////////////////////////////
        [getInvoicecurerncy.fulfilled]: (state, action) => {
            state.Invoicecurerncy = action.payload
            state.loading = false
        },
        [getInvoicecurerncy.rejected]: (state) => {
            state.loading = false
        },
        [getInvoicecurerncy.pending]: (state) => {
            state.loading = true
        },


        [putInvoicecurerncy.fulfilled]: (state, action) => {

            const cor = [...state.Invoicecurerncy]
            state.Invoicecurerncy = cor.map((cor_) => cor_.id === action.payload.id ? action.payload : cor_)
            state.loading = false
        },
        [putInvoicecurerncy.rejected]: (state) => {
            state.loading = false
        },
        [putInvoicecurerncy.pending]: (state) => {
            state.loading = true
        },
        [postInvoicecurerncy.fulfilled]: (state, action) => {
            state.Invoicecurerncy = [...state.Invoicecurerncy, action.payload]
            state.loading = false
        },
        [postInvoicecurerncy.rejected]: (state) => {
            state.loading = false
        },
        [postInvoicecurerncy.pending]: (state) => {
            state.loading = true
        },

        [DeleteInvoicecurerncy.fulfilled]: (state, action) => {
            state.Invoicecurerncy = action.payload
            state.loading = false
        },
        [DeleteInvoicecurerncy.rejected]: (state) => {
            state.loading = false
        },
        [DeleteInvoicecurerncy.pending]: (state) => {
            state.loading = true
        },

///////////////////////////////////

        [getCurrencyexchange.fulfilled]: (state, action) => {
            state.Currencyexchange = action.payload
            state.loading = false
        },
        [getCurrencyexchange.rejected]: (state) => {
            state.loading = false
        },
        [getCurrencyexchange.pending]: (state) => {
            state.loading = true
        },
        [putCurrencyexchange.fulfilled]: (state, action) => {
            
            const cor = [...state.Currencyexchange]
            state.Currencyexchange = cor.map((cor_) => cor_.id === action.payload.id ? action.payload : cor_)
            state.loading = false
        },
        [putCurrencyexchange.rejected]: (state) => {
            state.loading = false
        },
        [putCurrencyexchange.pending]: (state) => {
            state.loading = true
        },
        [postCurrencyexchange.fulfilled]: (state, action) => {
            state.Currencyexchange = [...state.Currencyexchange, action.payload]
            state.loading = false
        },
        [postCurrencyexchange.rejected]: (state) => {
            state.loading = false
        },
        [postCurrencyexchange.pending]: (state) => {
            state.loading = true
        },

        [DeleteCurrencyexchange.fulfilled]: (state, action) => {
            state.Invoicecurerncy = action.payload
            state.loading = false
        },
        [DeleteCurrencyexchange.rejected]: (state) => {
            state.loading = false
        },
        [DeleteCurrencyexchange.pending]: (state) => {
            state.loading = true
        },

        ///////////////////////////////////
        [getCountryoforigin.fulfilled]: (state, action) => {
            state.Countryoforigin = action.payload
            state.loading = false
        },
        [getCountryoforigin.rejected]: (state) => {
            state.loading = false
        },
        [getCountryoforigin.pending]: (state) => {
            state.loading = true
        },
        [putCountryoforigin.fulfilled]: (state, action) => {
            const cor = [...state.Countryoforigin]
            state.Countryoforigin = cor.map((cor_) => cor_.id === action.payload.id ? action.payload : cor_)
            state.loading = false
        },
        [putCountryoforigin.rejected]: (state) => {
            state.loading = false
        },
        [putCountryoforigin.pending]: (state) => {
            state.loading = true
        },
        [postCountryoforigin.fulfilled]: (state, action) => {
            state.Countryoforigin = [...state.Countryoforigin, action.payload]
            state.loading = false
        },
        [postCountryoforigin.rejected]: (state) => {
            state.loading = false
        },
        [postCountryoforigin.pending]: (state) => {
            state.loading = true
        },
        
    }
})

export const { 
    setStockinguom,
    setInvoicecurerncy,
    setCountryoforigin,
    setCurrencyexchange,
    setItem,
    setAdvicekind
} = dataSlice.actions

export default dataSlice.reducer
