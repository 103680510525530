import ApiService from "./ApiService"

 //

export async function apiGetAdvicekind() {
    return ApiService.fetchData({
        url: '/api/Advicekind/',
        method: 'get'
    })
}


export async function apiPostAdvicekind(data) {
    return ApiService.fetchData({
        url: '/api/Advicekind/',
        method: 'post',
        data
    })
}

export async function apiPutAdvicekind(data) {
    return ApiService.fetchData({
        url: '/api/Advicekind/' + data.id + '/',
        method: 'put',
        data
    })
}

export async function apiDeleteAdvicekind(data) {
    return ApiService.fetchData({
        url: '/api/Advicekind/' + data.id + '/',
        method: 'delete',
        data
    })
}




export async function apiGetStockinguom() {
    return ApiService.fetchData({
        url: '/api/Stockinguom/?ordering=-id',
        method: 'get'
    })
}

export async function apiPostStockinguom(data) {
    return ApiService.fetchData({
        url: '/api/Stockinguom/',
        method: 'post',
        data
    })
}

export async function apiPutStockinguom(data) {
    return ApiService.fetchData({
        url: '/api/Stockinguom/' + data.id + '/',
        method: 'put',
        data
    })
}

export async function apiDeleteStockinguom(data) {
    return ApiService.fetchData({
        url: '/api/Stockinguom/' + data.id + '/',
        method: 'delete',
        data
    })
}

