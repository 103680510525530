import ApiService from "./ApiService"

export async function apiGetBOECount() {
    return ApiService.fetchData({
        url: '/notification/count',
        method: 'get'
    })
}

export async function apiGetBOEByID(id) {
    return ApiService.fetchData({
        url: '/api/Billofentry/' + id + "/",
        method: 'get'
    })
}
//apiGetCustomsBOEs
export async function apiGetCustomsBOEs() {
    return ApiService.fetchData({
        url: '/api/CustomDB/',
        method: 'get'
    })
}


export async function apiGetBOEs() {
    return ApiService.fetchData({
        url: '/api/BillofentryPOST/?ordering=-id',
        method: 'get'
    })
}

export async function apiGetBOEsItems() {
    return ApiService.fetchData({
        url: '/api/ItembillPOST/',
        method: 'get'
    })
}

export async function apiPostBOE(data) {
    return ApiService.fetchData({
        url: '/api/BillofentryPOST/',
        method: 'post',
        data
    })
}

export async function apiPutBOE(data) {

    return ApiService.fetchData({
        url: '/api/BillofentryPOST/' + data.get("id") + "/",
        method: 'put',
        data
    })
}

export async function apiDeleteBOE(data) {
    return ApiService.fetchData({
        url: '/api/Billofentry/' + data.id + "/",
        method: 'delete',
        data
    })
}

export async function apiDeleteBOEItem(data) {
    return ApiService.fetchData({
        url: '/api/ItembillPOST/' + data.id + "/",
        method: 'delete',
        data
    })
}

export async function apiPutBOEItem(data) {

    return ApiService.fetchData({
        url: '/api/ItembillPOST/' + data.get("id") + "/",
        method: 'put',
        data
    })
}

export async function apiPostBOEItem(data) {
    return ApiService.fetchData({
        url: '/api/ItembillPOST/',
        method: 'post',
        data
    })
}

