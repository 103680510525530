import ApiService from "./ApiService"

// export async function apiGetDACount() {
//     return ApiService.fetchData({
//         url: '/notification/count',
//         method: 'get'
//     })
// }

export async function apiGetItemBillID(id) {
    return ApiService.fetchData({
        url: '/api/Itembill/'+id +"/",
        method: 'get'
    })
}
//



export async function apiGetItemQty(id) {
    return ApiService.fetchData({
        url: '/api/getitemqty/' + id + "/",
        method: 'get'
    })
}
export async function apiGetDAByID(id) {
    return ApiService.fetchData({
        url: '/api/Deliveryadvicetable/' + id + "/",
        method: 'get'
    })
}

export async function apiGetDAs() {
    return ApiService.fetchData({
        url: '/api/DeliveryadvicetablePOST/?ordering=-id',
        method: 'get'
    })
}

export async function apiGetDAItems() {
    return ApiService.fetchData({
        url: '/api/DeliveryadviceitemPOST/',
        method: 'get'
    })
}

//

export async function apiPostDA(data) {
    return ApiService.fetchData({
        url: '/api/DeliveryadvicetablePOST/',
        method: 'post',
        data
    })
}

export async function apiPutDA(data) {

    return ApiService.fetchData({
        url: '/api/DeliveryadvicetablePOST/' + data.get("id") + "/",
        method: 'put',
        data
    })
}

export async function apiDeleteDA(data) {
  
    return ApiService.fetchData({
        url: '/api/DeliveryadvicetablePOST/' + data.id + "/",
        method: 'delete',
        data
    })
}

export async function apiDeleteDAItem(data) {
    console.log(data)

    return ApiService.fetchData({
        url: '/api/DeliveryadviceitemPOST/' + data.id + "/",
        method: 'delete',
        data
    })
}

export async function apiPutDAItem(data) {
    console.log(data.get("id"))
    return ApiService.fetchData({
        url: '/api/DeliveryadviceitemPOST/' + data.get("id") + "/",
        method: 'put',
        data
    })
}

export async function apiPostDAItem(data) {
    return ApiService.fetchData({
        url: '/api/DeliveryadviceitemPOST/',
        method: 'post',
        data
    })
}

