import ApiService from "./ApiService"



export async function apiGetCurrencyexchange() {
    return ApiService.fetchData({
        url: '/api/Currencyexchange/?ordering=-id',
        method: 'get'
    })
}

export async function apiPostCurrencyexchange(data) {
    return ApiService.fetchData({
        url: '/api/Currencyexchange/',
        method: 'post',
        data
    })
}

export async function apiPutCurrencyexchange(data) {
    return ApiService.fetchData({
        url: '/api/Currencyexchange/' + data.id + '/',
        method: 'put',
        data
    })
}

export async function apiDeleteCurrencyexchange(data) {
    return ApiService.fetchData({
        url: '/api/Currencyexchange/' + data.id + '/',
        method: 'delete',
        data
    })
}

