import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'




const stateSlice = createSlice({
    name: 'BOEs/state',
    initialState: {
        drawerOpen: false,
        
        sortedColumn: () => {},
        sortedColumnCustom: () => { },
    },

    reducers: {
       
        setSortedColumn: (state, action) => {
            state.sortedColumn = action.payload
        },
        setSortedColumnCustom: (state, action) => {
            state.sortedColumnCustom = action.payload
        },
        setDrawerOpen: (state) => {
            state.drawerOpen = true
        },
        setDrawerClose: (state) => {
            state.drawerOpen = false
        } 
    },
})

export const { 
   
    setDrawerOpen, 
    setDrawerClose,
    setSortedColumnCustom,
    setSortedColumn
} = stateSlice.actions

export default stateSlice.reducer
