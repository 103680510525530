import ApiService from "./ApiService"



export async function apiGetCountryoforigin() {
    return ApiService.fetchData({
        url: '/api/Countryoforigin/?ordering=-id',
        method: 'get'
    })
}

export async function apiPostCountryoforigin(data) {
    return ApiService.fetchData({
        url: '/api/Countryoforigin/',
        method: 'post',
        data
    })
}

export async function apiPutCountryoforigin(data) {
 
    return ApiService.fetchData({
        url: '/api/Countryoforigin/' + data.id +'/',
        method: 'put',
        data
    })
}

export async function apiDeleteCountryoforigin(data) {
    return ApiService.fetchData({
        url: '/api/Countryoforigin/' + data.id + '/',
        method: 'delete',
        data
    })
}

